import {FC, useState} from 'react'
import Modal from 'react-bootstrap/Modal'
import {useIntl} from 'react-intl'
import {useAppSelector} from '../../../../../setup/redux/hooks'
import {selectUser} from '../../../../modules/auth/redux/AuthRedux'

import Button from 'react-bootstrap/Button'

import './downloadOrderModal.scss'
import PaymentInformation from '../paymentInfomation'
import {OrderPaymentModel} from '../../models/OrderPaymentModel'
import {PAYMENT_METHOD, YEN_RATE} from '../../../../shared/constants'
import {CurrencyCode} from '../../../pricing/pricing.constant'
import {COUNTRY_CODES} from '../../../../modules/profile/constants'

type Props = {
  className?: string
  isShow: boolean
  onClose: any
  onOk: any
  onPaymentMethodChange: (method: string) => void
  orderDetails: OrderPaymentModel[]
  currency: CurrencyCode
}

const PayOrderModal: FC<Props> = (props) => {
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  const [paymentMethod, setPaymentMethod] = useState(PAYMENT_METHOD.CREDIT)
  const userBalance = user?.points ?? 0
  const totalAmount = props.orderDetails.reduce(
    (accumulator, order) => accumulator + order.unitPrice * order.quantity,
    0
  )
  //only allow paid when user balance is enough but for only payment method === credit
  //auto true for payment method === bank
  const isAllowPaid = userBalance >= totalAmount || paymentMethod === PAYMENT_METHOD.VISA

  return (
    <Modal
      size='lg'
      aria-labelledby='contained-modal-title-vcenter'
      centered
      show={props.isShow}
      onHide={props.onClose}
      backdrop='static'
    >
      <Modal.Header closeButton>
        <h2 className='fw-bolder'>{intl.formatMessage({id: 'Order.payment.header'})}</h2>
      </Modal.Header>      
      <Modal.Body>
        <PaymentInformation
          totalOrder={totalAmount}
          currency={props.currency}
          fiatAmount={props.currency === CurrencyCode.JPY ? Math.round(totalAmount * YEN_RATE) : totalAmount}
          orderDetails={props.orderDetails}
          onPaymentMethodChange={(paymentMethod: string) => {
            setPaymentMethod(paymentMethod as PAYMENT_METHOD)
            props.onPaymentMethodChange(paymentMethod)
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        <div className='d-flex justify-content-end flex-row-fluid'>
          <Button
            type='button'
            variant='secondary'
            onClick={props.onClose}
            className='btn btn-light me-3'
          >
            {intl.formatMessage({id: 'MODAL.PLAN.cancel_btn'})}
          </Button>

          <Button onClick={props.onOk} disabled={!isAllowPaid} type='submit' variant='primary'>
            {intl.formatMessage({id: 'Order.payment.paid'})}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  )
}
export default PayOrderModal
