import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import profileReducer from '../../app/modules/profile/redux/ProfileRedux'
import profileSaga from '../../app/modules/profile/redux/ProfileSaga'
import serviceReducer from '../../app/modules/services/redux/serviceRedux'
import serviceSaga from '../../app/modules/services/redux/serviceSaga'
import authReducer from '../../app/modules/auth/redux/AuthRedux'
import authSaga from '../../app/modules/auth/redux/AuthSaga'
import orderReducer from '../../app/pages/orders/redux/OrderRedux'
import orderSaga from '../../app/pages/orders/redux/orderSaga'
import SettingReferenceReducer from '../../app/pages/setting-reference/redux/SettingReferenceRedux'
import SettingReferenceSaga from '../../app/pages/setting-reference/redux/settingReferrenceSaga'
import BillingReducer from '../../app/pages/billings/redux/BillingRedux'
import BillingSaga from '../../app/pages/billings/redux/BillingSaga'
import ItemReducer from '../../app/pages/items/redux/itemRedux'
import ItemSaga from '../../app/pages/items/redux/itemSaga'
import PricingReducer from '../../app/pages/pricing/redux/pricingRedux'
import PricingSaga from '../../app/pages/pricing/redux/pricingSaga'
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
export const rootReducer = (history: History) => combineReducers({
  auth: authReducer,
  profile: profileReducer,
  settingReference: SettingReferenceReducer,
  order: orderReducer,
  service: serviceReducer,
  item: ItemReducer,
  pricing: PricingReducer,
  billing: BillingReducer,
  router: connectRouter(history) // Ensure this is correctly set up
})



export function* rootSaga() {
  yield all([
    authSaga(),
    serviceSaga(),
    SettingReferenceSaga(),
    profileSaga(),
    orderSaga(),
    ItemSaga(),
    PricingSaga(),
    BillingSaga()
  ])
}
