// intl.ts
import { createIntl, createIntlCache, IntlShape } from 'react-intl';
import enMessages from '../../_metronic/i18n/messages/en.json'
import jaMessages from '../../_metronic/i18n/messages/ja.json'


const cache = createIntlCache();
const allMessages = {
    en: enMessages,
    ja: jaMessages,
}
let intl: IntlShape;

export const initIntl = (locale: "en" | "ja"): void => {
    intl = createIntl({
        locale,
        messages: allMessages[locale],
    }, cache);
};

export const getIntl = (): IntlShape => intl;

// Initialize with default locale
initIntl(navigator.language === "ja" ? "ja" : "en");