/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState, useRef, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {Link, RouteComponentProps, useLocation} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import OrderInfoBox from './components/orderInfoBox'
import ItemTable from './components/itemTable'
import ItemGrid from './components/itemGrid'
import {useDispatch} from 'react-redux'
import {
  filterOrderItemsChange,
  getAiServiceStatus,
  getDetail,
  selectAiServiceStatus,
  selectCurrentOrder,
  selectCurrentOrderItems,
  selectCurrentOrderItemsFilter,
  selectSelectedItems,
  selectSelectedMode,
  setAsyncWorkingOrders,
  setSelectMode,
  updateCurrent,
  updateCurrentOrderItems,
} from './redux/OrderRedux'
import {useAppSelector} from '../../../setup/redux/hooks'
import {OrderModel} from './models/OrderModel'
import {listenOrderDetailChange, listenOrderItemsChange} from './firebase/order'
import {OrderItemModel} from './models/OrderItemModel'
import {DatabaseReference, off} from 'firebase/database'
import OrderActionBox from './components/OrderActionBox'
import {
  ORDER_ACTION_VIEW_MODE,
  OrderActionType,
  OrderStatus,
  PaymentStatus,
  TIMEOUT_SHOW_ADS,
} from './constants'
import Tooltip from 'react-tooltip-lite'
import InfiniteScroll from 'react-infinite-scroll-component'
import {selectIsShowWelcomeModal} from '../../modules/auth'
import {toast} from 'react-toastify'
import {OrderActionModel} from './models/OrderActionModel'
import {getIntl} from '../../shared/intl'

interface RouteParams {
  id: string
}
const enum OrderDetailViewMode {
  List = 'List',
  Grid = 'Grid',
}
interface Props extends RouteComponentProps<RouteParams> {}
const OrderDetail: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const [orderViewMode, setViewMode] = useState(OrderDetailViewMode.List)
  const didRequest = useRef(false)
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const downloadUrl = queryParams.get('downloadUrl')
  const orderId = props.match.params.id
  const isAiServerOverload = useAppSelector(selectAiServiceStatus)
  // let orderListener: DatabaseReference | undefined = undefined
  // let itemListener: DatabaseReference | undefined = undefined

  const order: OrderModel | undefined = useAppSelector(selectCurrentOrder)
  const orderItems = useAppSelector(selectCurrentOrderItems)
  const orderItemsFilter = useAppSelector(selectCurrentOrderItemsFilter)
  const selectedMode = useAppSelector(selectSelectedMode)

  const selectedItems = useAppSelector(selectSelectedItems)
  const fetchData = () => {
    dispatch(filterOrderItemsChange({...orderItemsFilter, page: Number(orderItemsFilter.page) + 1}))
  }

  const handleOrderChangeFromFirebase = (updatedOrder: OrderModel) => {
    dispatch(updateCurrent(updatedOrder))
  }
  const handleOrderItemsChangeFromFirebase = (items: OrderItemModel[]) => {
    dispatch(updateCurrentOrderItems(Object.values(items)))
  }
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getAiServiceStatus())
      dispatch(getDetail(orderId))
      if (downloadUrl) {
        const intl = getIntl()
        let downloadToast = toast.info(intl.formatMessage({id: `MESSAGE.ORDER.DOWNLOADING`}), {
          hideProgressBar: true,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: false,
        })

        const orderAction = {
          orderId: Number(orderId),
          type: OrderActionType.Download,
          asyncListenerPath: downloadUrl,
          toast: downloadToast,
        } as OrderActionModel
        dispatch(setAsyncWorkingOrders(orderAction))
      }
    }
    const orderListener = listenOrderDetailChange(orderId, (order: OrderModel) => {
      if (order) {
        handleOrderChangeFromFirebase(order)
      }
    })
    const itemListener = listenOrderItemsChange(orderId, (items: OrderItemModel[]) => {
      if (items) {
        handleOrderItemsChangeFromFirebase(items)
      }
    })
    return () => {
      didRequest.current = true
      if (itemListener) {
        off(itemListener)
      }
      if (orderListener) {
        off(orderListener)
      }
    }
  }, [downloadUrl, orderId])

  return (
    <>
      {order && (
        <PageTitle
          breadcrumbs={[
            {
              title: intl.formatMessage({id: 'ORDER.LIST'}),
              path: '/orders',
              isSeparator: false,
              isActive: true,
            },
            {
              title: 'dash',
              path: 'dash',
              isSeparator: true,
              isActive: true,
            },
          ]}
        >
          {order.name}
        </PageTitle>
      )}
      {order && order.status === OrderStatus.AI_PROCESSING && isAiServerOverload && (
        <div className='mx-1 mb-5'>
          <div className='d-flex bg-light-warning rounded border-warning border border-dashed p-6'>
            <i className='bi bi-exclamation-circle-fill fs-2tx text-warning me-4'></i>
            <div className='d-flex flex-stack flex-grow-1'>
              <div className='fw-bold'>
                <h4 className='text-gray-800 fw-bolder'>
                  {intl.formatMessage({id: 'Order.ai.overloaded.title'})}
                </h4>
                <div className='fs-6 text-gray-600'>
                  {intl.formatMessage({id: 'Order.ai.overloaded'})}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {order && (
        <div className='row'>
          <div className='col-xl-9'>
            <div className='card'>
              <div className='card-header'>
                <h3 className='card-title fw-bold text-dark'>
                  {intl.formatMessage({id: 'OrderDetail.header.ORDER_ITEMS'})}
                  {order.status === OrderStatus.AI_PROCESSING &&
                    '    (' + order.completedItems + '/' + order.totalItems + ')'}
                  {selectedMode &&
                    ' - ' +
                      intl.formatMessage({id: 'ORDER.ACTIONS.SELECT'}) +
                      ' ' +
                      selectedItems.length +
                      '/' +
                      order.totalItems}
                </h3>
                <div className='card-toolbar'>
                  <OrderActionBox order={order} viewMode={ORDER_ACTION_VIEW_MODE.MINIFY} />
                  {(order.status === OrderStatus.COMPLETED ||
                    order.status === OrderStatus.PENDING) && (
                    <Tooltip
                      content={intl.formatMessage({id: 'ORDER.ACTIONS.SELECT'})}
                      className='tooltips'
                    >
                      <button
                        type='button'
                        className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1 p-1'
                        onClick={() => {
                          dispatch(setSelectMode(!selectedMode))
                        }}
                      >
                        <KTSVG
                          path={'/media/icons/duotune/coding/cod006.svg'}
                          className='svg-icon svg-icon-2x svg-icon-info'
                        />
                      </button>
                    </Tooltip>
                  )}
                  <Tooltip
                    content={intl.formatMessage({id: 'ORDER.ACTIONS.BACK'})}
                    className='tooltips'
                  >
                    <Link to={'/orders'} className='btn btn-icon btn-sm btn-secondary ms-1'>
                      <i className='bi fs-1 bi-arrow-left-short'></i>
                    </Link>
                  </Tooltip>
                  <button
                    type='button'
                    className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                    onClick={() =>
                      setViewMode(
                        orderViewMode === OrderDetailViewMode.List
                          ? OrderDetailViewMode.Grid
                          : OrderDetailViewMode.List
                      )
                    }
                  >
                    <KTSVG
                      path='/media/icons/duotune/general/gen008.svg'
                      className=' svg-icon svg-icon-2x svg-icon-dark'
                    />
                  </button>
                </div>
              </div>
              <div className='card-body p-5'>
                <InfiniteScroll
                  dataLength={orderItems.length} //This is important field to render the next data
                  next={fetchData}
                  style={{
                    overflowX: 'hidden',
                  }}
                  hasMore={orderItems.length !== orderItemsFilter.itemCount}
                  loader={
                    <h4 className='mt-5' style={{textAlign: 'center'}}>
                      {/* {intl.formatMessage({id: 'SYSTEM.LOADING'})} */}
                    </h4>
                  }
                  // endMessage={
                  //   <p className='mt-3' style={{textAlign: 'center'}}>
                  //     <b>{intl.formatMessage({id: 'SYSTEM.TABLE.SEEN_ALL'})}</b>
                  //   </p>
                  // }
                >
                  {orderViewMode === OrderDetailViewMode.List ? (
                    <ItemTable items={orderItems} />
                  ) : (
                    <ItemGrid items={orderItems} order={order} />
                  )}
                </InfiniteScroll>
              </div>
            </div>
          </div>
          <div className=' col-xl-3'>
            <OrderInfoBox order={order} />
          </div>
        </div>
      )}
    </>
  )
}

export {OrderDetail}
