import {useIntl} from 'react-intl'
import {useFormik} from 'formik'
import * as Yup from 'yup'

import {useEffect, useState} from 'react'
import {KTSVG} from '../../../_metronic/helpers'
import {useDispatch} from 'react-redux'
import {selectAuthLoading, sendVerifyEmail, verifyEmail} from '../../modules/auth'
import {useAppSelector} from '../../../setup/redux/hooks'
function VerifyUserPage() {
  const intl = useIntl()
  const [code, setCode] = useState(Array(6).fill(''))
  const [resendDisabled, setResendDisabled] = useState(false)
  const [timer, setTimer] = useState(60) // Initial timer set to 60 seconds
  const loading = useAppSelector(selectAuthLoading)
  const dispatch = useDispatch()
  useEffect(() => {
    let interval: any
    if (resendDisabled && timer > 0) {
      interval = setInterval(() => setTimer((prev) => prev - 1), 1000)
    } else if (timer === 0) {
      setResendDisabled(false)
    }
    return () => clearInterval(interval)
  }, [resendDisabled, timer])
  const handleChange = (e: any, index: number) => {
    const value = e.target.value.replace(/[^0-9]/g, '') // Only allow numbers
    if (value.length > 1) return // Ignore if more than 1 character
    const newCode = [...code]
    newCode[index] = value
    setCode(newCode)

    // Move focus to next box if value is entered
    if (value && index < 5) {
      document.getElementById(`code-${index + 1}`)?.focus()
    }
  }
  const handleKeyDown = (e: any, index: number) => {
    // Handle backspace to go to the previous input
    if (e.key === 'Backspace' && !code[index] && index > 0) {
      document.getElementById(`code-${index - 1}`)?.focus()
    }
  }

  const handlePaste = (e: any) => {
    e.preventDefault()
    const pasteData = e.clipboardData
      .getData('Text')
      .slice(0, 6)
      .replace(/[^0-9]/g, '')
    if (pasteData.length === 6) {
      setCode(pasteData.split(''))
      document.getElementById('code-5')?.focus() // Move focus to the last input
    }
  }
  const handleResend = (e: any) => {
    e.preventDefault()
    e.stopPropagation()
    if (resendDisabled) return
    setResendDisabled(true)
    setTimer(60) // Reset the timer
    dispatch(sendVerifyEmail())
  }

  return (
    <form className='form w-100 w-lg-500px ' onPaste={handlePaste} noValidate>
      <div className='text-center mb-10'>
        <KTSVG
          path='/media/icons/duotune/general/gen051.svg'
          className='svg-icon svg-icon-10x svg-icon-success'
        />
        <h1 className='text-dark mb-3 mt-3'>{intl.formatMessage({id: 'VERIFY.header'})}</h1>
        <div className='fw-bold fs-4'>{intl.formatMessage({id: 'VERIFY.description1'})}</div>
        <div className='fw-bold fs-4 mb-10'>{intl.formatMessage({id: 'VERIFY.description2'})}</div>
        <div className='fv-row mb-10 d-flex justify-content-between'>
          {code.map((digit, index) => (
            <input
              key={index}
              id={`code-${index}`}
              type='text'
              inputMode='numeric'
              maxLength={1}
              value={digit}
              onChange={(e) => handleChange(e, index)}
              onKeyDown={(e) => handleKeyDown(e, index)}
              className='form-control  text-center me-2'
              style={{width: '70px'}}
            />
          ))}
        </div>
        <div className='text-center'>
          <button
            disabled={code.some((digit) => digit === '') || loading}
            className='btn btn-lg btn-primary w-100 mb-5'
            onClick={() => {
              const verificationCode = code.join('')
              dispatch(verifyEmail(verificationCode))
            }}
          >
            {loading ? (
              <span className='indicator-progress' style={{display: 'block'}}>
                {intl.formatMessage({id: 'Login.form.WAITING'})}
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            ) : (
              <span className='indicator-label'>{intl.formatMessage({id: 'VERIFY.button'})}</span>
            )}
          </button>
        </div>
        <div className='text-gray-400 fw-bold fs-4'>
          {intl.formatMessage({id: 'VERIFY.notReceived'})}
          <span
            onClick={handleResend}
            className={
              'ms-1 fw-bolder border-0 cursor-pointer' +
              (resendDisabled ? ' link-secondary' : ' link-primary')
            }
          >
            {resendDisabled
              ? intl.formatMessage({id: 'VERIFY.resendIn'}, {timer})
              : intl.formatMessage({id: 'VERIFY.resend'})}
          </span>
        </div>
      </div>
    </form>
  )
}
export default VerifyUserPage
