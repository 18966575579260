import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IProfileDetails,
  ISubscriptionPlanModel,
  IProfileState,
  UpdateProfilePayload,
  ChangePasswordPayload,
  UserInvoiceModel,
} from '../models/ProfileModels'
import { RootState } from '../../../../setup/redux/Store'
import { toast } from 'react-toastify'
import { HTTP_CODE } from '../../../shared/constants'
import { intl } from '../../../../_metronic/i18n/i18nProvider'

const initialState: IProfileState = {
  loading: false,
  profile: undefined,
  showProfileEditor: false,
  showPasswordChange: false,
  subscriptionPlans: [],
  selectedSubscriptionPlan: undefined,
  showBankTransferRequestModal: false,
  showInvoiceEditor: false,
}

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setBankTransferRequestModal: (state, action: PayloadAction<boolean>) => {
      state.showBankTransferRequestModal = action.payload
    },
    setShowInvoiceEditor: (state, action: PayloadAction<boolean>) => {
      state.showInvoiceEditor = action.payload
      if (!action.payload) {
        state.showBankTransferRequestModal = false
      }
    },
    getUserProfile: (state) => {
      state.loading = true
    },
    updateUserProfile: (state, action: PayloadAction<{ profile: UpdateProfilePayload }>) => {
      state.loading = true
    },
    updateUserInvoice: (state, action: PayloadAction<{ data: UserInvoiceModel, autoSubmit: boolean }>) => {
      state.loading = true
    },
    setUserProfile: (state, action: PayloadAction<{ profile: IProfileDetails }>) => {
      state.loading = false
      state.profile = action.payload.profile
    },
    verifyUser: (state) => {
      state.loading = true
    },
    redeemReferralCode: (state, action: PayloadAction<{ referralCode: string }>) => {
      state.loading = true
    },
    redeemReferralCodeSuccess: (
      state,
      action: PayloadAction<{ referralCode: string; points: number }>
    ) => {
      state.loading = false
      if (state.profile) {
        state.profile.referenceCode = action.payload.referralCode
        state.profile.points = action.payload.points
      }
    },
    setShowProfileEditor: (state, action: PayloadAction<{ show: boolean }>) => {
      state.showProfileEditor = action.payload.show
    },
    setShowPasswordChange: (state, action: PayloadAction<{ show: boolean }>) => {
      state.showPasswordChange = action.payload.show
    },
    changePassword: (state, action: PayloadAction<ChangePasswordPayload>) => {
      state.loading = true
    },
    changePasswordSuccess: (state) => {
      state.loading = false
    },
    getSubscriptionPlans: (state, action: PayloadAction<{ type: string }>) => {
      state.loading = true
    },
    setSubscriptionPlans: (
      state,
      action: PayloadAction<{ subscriptionPlans: ISubscriptionPlanModel[] }>
    ) => {
      state.loading = false
      state.subscriptionPlans = action.payload.subscriptionPlans
      const selectedPlan = state.subscriptionPlans.filter(
        (plan) => plan.id === state.profile?.planId
      )
      if (selectedPlan.length > 0) {
        state.selectedSubscriptionPlan = selectedPlan[0]
      }
    },
    setSelectedSubscriptionPlan: (
      state,
      action: PayloadAction<{ subscriptionPlan: ISubscriptionPlanModel }>
    ) => {
      state.selectedSubscriptionPlan = action.payload.subscriptionPlan
    },
    updateUserSubscriptionPlan: (state) => {
      state.loading = true
    },
    showError: (state, action: PayloadAction<{ error: any }>) => {
      state.loading = false

      const error = action.payload.error
      let errors = [error?.message]
      const status = error?.response?.status
      if (status === HTTP_CODE.BAD_REQUEST) {
        errors = error.response?.data?.messages
      }

      toast.error(intl.formatMessage({ id: "SERVER." + errors[0] }), {
        autoClose: 3000,
        hideProgressBar: true,
      })
    },
  },
})

export const {
  getUserProfile,
  updateUserProfile,
  setUserProfile,
  redeemReferralCode,
  redeemReferralCodeSuccess,
  changePassword,
  changePasswordSuccess,
  getSubscriptionPlans,
  setSubscriptionPlans,
  setSelectedSubscriptionPlan,
  updateUserSubscriptionPlan,
  setShowProfileEditor,
  verifyUser,
  setShowPasswordChange,
  showError,
  setBankTransferRequestModal,
  updateUserInvoice,
  setShowInvoiceEditor
} = profileSlice.actions

export const selectProfileLoading = (state: RootState) => state.profile.loading
export const selectUserProfile = (state: RootState) => state.profile.profile
export const selectReferralCode = (state: RootState) => state.profile.profile?.referenceCode
export const selectShowProfileEditor = (state: RootState) => state.profile.showProfileEditor
export const selectShowPasswordChange = (state: RootState) => state.profile.showPasswordChange
export const selectSubscriptionPlans = (state: RootState) => state.profile.subscriptionPlans
export const selectSelectedSubscriptionPlan = (state: RootState) => state.profile.selectedSubscriptionPlan
export const selectShowBankTransferRequestModal = (state: RootState) => state.profile.showBankTransferRequestModal
export const selectShowBankInvoiceEditor = (state: RootState) => state.profile.showInvoiceEditor

export default profileSlice.reducer
