import {FC, useRef, useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import {LayoutSplashScreen} from '../../../../_metronic/layout/core'
import {logout, requestUser, selectAccessToken} from '../redux/AuthRedux'
import {useAppSelector} from '../../../../setup/redux/hooks'

const AuthInit: FC = (props) => {
  const didRequest = useRef(false)
  const dispatch = useDispatch()
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const accessToken: string | undefined = useAppSelector(selectAccessToken)
  // We should request user by authToken before rendering the application
  useEffect(() => {
    const verifyUser = async () => {
      if (!didRequest.current) {
        await dispatch(requestUser())
        setShowSplashScreen(false)
      }
      return () => (didRequest.current = true)
    }
    if (accessToken) {
      verifyUser()
    } else {
      dispatch(logout())
      setShowSplashScreen(false)
    }
  }, [dispatch, accessToken])

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>
}

export default AuthInit
