import clsx from 'clsx'
import {useFormik} from 'formik'
import {useEffect} from 'react'
import {useIntl} from 'react-intl'
import * as Yup from 'yup'
import {useDispatch, useSelector} from 'react-redux'
import {IProfileDetails, UserInvoiceModel} from '../models/ProfileModels'
import {
  selectProfileLoading,
  selectUserProfile,
  setShowInvoiceEditor,
  updateUserInvoice,
} from '../redux/ProfileRedux'
import {PHONE_REGEX, POSTCODE_REGEX} from '../../../shared/constants'
import {getUserFullName} from '../../../shared/utils'
import {useAppSelector} from '../../../../setup/redux/hooks'

interface InvoiceEditorProps {
  isAutoSubmitRequest: boolean // Add your props here
  handleCancel?: () => void
  cancelText?: string
  handleSuccess?: () => void
}
const InvoiceEditor: React.FC<InvoiceEditorProps> = (props) => {
  const dispatch = useDispatch()
  const intl = useIntl()
  const profile: IProfileDetails | undefined = useSelector(selectUserProfile)
  const isUpdating = useAppSelector(selectProfileLoading)
  const initialValues: UserInvoiceModel = {
    company: profile?.companyName || '',
    department: '',
    title: '',
    licenseNumber: '',
    fullName: profile ? getUserFullName(profile, intl.locale) : '',
    postCode: '',
    address: profile?.companyAddress || '',
    phone: profile?.phone || '',
    mobile: '',
    email: profile?.email || '',
  }

  const invoiceDetailsSchema = Yup.object().shape({
    company: Yup.string()
      .required(intl.formatMessage({id: 'Registration.form.COMPANY_NAME.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'Registration.form.COMPANY_NAME.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.COMPANY_NAME.MAX'})),
    department: Yup.string().max(250, intl.formatMessage({id: 'Registration.form.DEPARTMENT.MAX'})),
    title: Yup.string().max(50, intl.formatMessage({id: 'Registration.form.COMPANY_TITLE.MAX'})),
    licenseNumber: Yup.string()
      .min(5, intl.formatMessage({id: 'Registration.form.LICENSE_NUMBER.MIN'}))
      .max(20, intl.formatMessage({id: 'Registration.form.LICENSE_NUMBER.MAX'})),
    fullName: Yup.string()
      .required(intl.formatMessage({id: 'Registration.form.FULL_NAME.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'Registration.form.FULL_NAME.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.FULL_NAME.MAX'})),
    postCode: Yup.string()
      .required(intl.formatMessage({id: 'Registration.form.POST_CODE.REQUIRED'}))
      .matches(POSTCODE_REGEX, intl.formatMessage({id: 'Registration.form.POST_CODE.FORMAT'})),
    address: Yup.string()
      .required(intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.REQUIRED'}))
      .min(1, intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.MIN'}))
      .max(250, intl.formatMessage({id: 'Registration.form.COMPANY_ADDRESS.MAX'})),
    phone: Yup.string().matches(
      PHONE_REGEX,
      intl.formatMessage({id: 'Registration.form.PHONE.FORMAT'})
    ),
    mobile: Yup.string().matches(
      PHONE_REGEX,
      intl.formatMessage({id: 'Registration.form.PHONE.FORMAT'})
    ),
    email: Yup.string()
      .email(intl.formatMessage({id: 'Registration.form.EMAIL.FORMAT'}))
      .min(1, intl.formatMessage({id: 'Registration.form.EMAIL.MIN'}))
      .max(50, intl.formatMessage({id: 'Registration.form.EMAIL.MAX'}))
      .required(intl.formatMessage({id: 'Registration.form.EMAIL.REQUIRED'})),
  })
  useEffect(() => {
    if (profile) {
      formik.resetForm({
        values: profile.invoiceInfo || initialValues,
      })
    }
  }, [profile])
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: invoiceDetailsSchema,
    onSubmit: async (values) => {
      dispatch(updateUserInvoice({data: values, autoSubmit: props.isAutoSubmitRequest}))
      setTimeout(() => {
        props.handleSuccess?.()
      }, 1000)
    },
  })

  return (
    <form onSubmit={formik.handleSubmit} noValidate className='form'>
      <div className='card-body p-9'>
        {Object.entries(initialValues).map(([key, value]) => {
          const isRequired =
            key === 'company' ||
            key === 'fullName' ||
            key === 'postCode' ||
            key === 'address' ||
            key === 'email'
          return (
            <div className='row mb-6' key={key}>
              <label
                className={
                  'col-lg-4 col-form-label ' + (isRequired ? 'required' : '') + ' fw-bold fs-6'
                }
              >
                {intl.formatMessage({id: 'Registration.form.' + key})}
              </label>
              <div className='col-lg-8 fv-row'>
                <input
                  type='text'
                  {...formik.getFieldProps(key)}
                  placeholder={intl.formatMessage({id: 'Registration.form.' + key + '.HOLDER'})}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid':
                        formik.touched[key as keyof UserInvoiceModel] &&
                        formik.errors[key as keyof UserInvoiceModel],
                    },
                    {
                      'is-valid':
                        formik.touched[key as keyof UserInvoiceModel] &&
                        !formik.errors[key as keyof UserInvoiceModel],
                    }
                  )}
                  autoComplete='off'
                />
                {formik.touched[key as keyof UserInvoiceModel] &&
                  formik.errors[key as keyof UserInvoiceModel] && (
                    <div className='fv-plugins-message-container text-danger mt-1 fs-7'>
                      {formik.errors[key as keyof UserInvoiceModel]}
                    </div>
                  )}
              </div>
            </div>
          )
        })}
      </div>

      <div className='card-footer d-flex justify-content-end py-6 px-9'>
        <button
          type='button'
          className='btn btn-light me-3'
          onClick={() => {
            dispatch(setShowInvoiceEditor(false))
            props.handleCancel?.()
          }}
        >
          {props.cancelText ?? intl.formatMessage({id: 'PROFILE.OVERVIEW.BUTTON2'})}
        </button>
        <button type='submit' className='btn btn-primary' disabled={isUpdating || !formik.isValid}>
          {isUpdating ? (
            <span className='indicator-progress' style={{display: 'block'}}>
              {intl.formatMessage({id: 'Login.form.WAITING'})}
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          ) : (
            intl.formatMessage({id: 'PROFILE.OVERVIEW.SUBMIT'})
          )}
        </button>
      </div>
    </form>
  )
}

export default InvoiceEditor
