import InvoiceEditor from '../../modules/profile/components/InvoiceEditor'
import {useAppSelector} from '../../../setup/redux/hooks'
import {selectUser} from '../../modules/auth'
import {useHistory} from 'react-router-dom'
import {USER_VERIFY_STATUS} from '../../modules/profile/constants'
import {useIntl} from 'react-intl'

export function BankTransferRequest() {
  const history = useHistory()
  const intl = useIntl()
  const user = useAppSelector(selectUser)
  if (user?.verifyStatus === USER_VERIFY_STATUS.VERIFYING) {
    history.push('/')
  }
  return (
    <div
      className='bg-white rounded shadow-sm p-10 p-lg-15 mx-auto '
      style={{width: '800px', marginTop: '5%'}}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>
          {intl.formatMessage({id: 'Registration.form.BANK_TRANSFER_TITLE'})}
        </h1>
      </div>
      <InvoiceEditor
        isAutoSubmitRequest={true}
        handleCancel={() => history.push('/')}
        handleSuccess={() => history.push('/')}
        cancelText={intl.formatMessage({id: 'Registration.form.SKIP'})}
      />
    </div>
  )
}
