import {FC, useEffect, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {BillingModel} from '../models/BillingModel'
import {
  filterDataChange,
  getAll,
  selectBillingFilter,
  selectBillingList,
} from '../redux/BillingRedux'
import {PaginateModel} from '../../../shared/models/PaginateModel'
import Paginator from '../../../components/Paginator'
import {UserTransactionAction, UserTransactionDataType, UserTransactionType} from '../constants'
import moment from 'moment'
import Tooltip from 'react-tooltip-lite'
import {KTSVG} from '../../../../_metronic/helpers'
import {downloadDocument} from '../redux/BillingApi'
import {toast} from 'react-toastify'
type Props = {
  className?: string
}
const BillingTable: FC<Props> = (props) => {
  const intl = useIntl()
  const dispatch = useDispatch()
  const didRequest = useRef(false)
  const data: BillingModel[] = useAppSelector(selectBillingList)
  const filter: PaginateModel = useAppSelector(selectBillingFilter)

  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getAll(undefined))
      didRequest.current = true
    }
  }, [])
  const handleActionRedirect = (target: any, newTab: boolean = false) => {
    if (newTab) {
      // window.open(target, '_blank')

      downloadDocument(target)
        .then((res) => {
          const url = res.data
          if (url) {
            window.open(url, '_blank')
          } else {
            toast.error(intl.formatMessage({id: 'SERVER.TRANSACTION_NOT_FOUND'}))
          }
        })
        .catch((e) => {
          const message = e.response?.data?.messages[0] ?? e.message
          toast.error(intl.formatMessage({id: 'SERVER.' + message}))
        })
    } else {
      window.location.href = target
    }
  }
  const handlePageChanged = (targetPage: number) => {
    dispatch(filterDataChange({...filter, page: targetPage}))
  }
  const renderItemActions = (item: BillingModel) => {
    let actions: any = []
    let data: any = item.data
    //check item.data is an object or not
    if (item.data && typeof item.data === 'string') {
      data = JSON.parse(item.data)
    }
    if (data && data.link) {
      switch (data.type) {
        case UserTransactionDataType.invoice:
          actions = [
            <Tooltip
              content={intl.formatMessage({id: 'SETTING_REFERENCE.ACTION.invoice'})}
              className='tooltips'
              key={'billing_action'}
            >
              <button
                onClick={() => handleActionRedirect(item.id, true)}
                className='btn btn-icon btn-light-info  btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/files/fil026.svg' className='svg-icon-3' />
              </button>
            </Tooltip>,
          ]
          break
        case UserTransactionDataType.orderDetail:
          actions = [
            <Tooltip
              content={intl.formatMessage({id: 'SETTING_REFERENCE.ACTION.Detail'})}
              className='tooltips'
              key={'billing_action'}
            >
              <button
                onClick={() => handleActionRedirect(data.link)}
                className='btn btn-icon btn-light-info  btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/files/fil024.svg' className='svg-icon-3' />
              </button>
            </Tooltip>,
          ]
          break
        case UserTransactionDataType.recipe:
          actions = [
            <Tooltip
              content={intl.formatMessage({id: 'SETTING_REFERENCE.ACTION.recipe'})}
              className='tooltips'
              key={'billing_action'}
            >
              <button
                onClick={() => handleActionRedirect(item.id, true)}
                className='btn btn-icon btn-light-info  btn-active-color-primary btn-sm me-1'
              >
                <KTSVG path='/media/icons/duotune/files/fil027.svg' className='svg-icon-3' />
              </button>
            </Tooltip>,
          ]
          break
        default:
          break
      }
    }
    return (
      <div className='d-flex justify-content-end flex-shrink-0'>
        {actions.map((action: any) => action)}
      </div>
    )
  }
  const generateDescriptionWithTypeAndData = (item: BillingModel) => {
    let data: any = item.data
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }
    switch (item.type) {
      case UserTransactionType.CREDIT:
        return intl.formatMessage(
          {id: 'BILLING.MESSAGE.CREDIT'},
          {
            name: intl.formatMessage({id: data?.target ?? ''}),
          }
        )
      case UserTransactionType.REFERRAL:
        return intl.formatMessage({id: 'BILLING.MESSAGE.REFERRAL'})
      case UserTransactionType.EARN:
        return intl.formatMessage(
          {id: 'BILLING.MESSAGE.EARN'},
          {
            reason: intl.formatMessage({id: data?.target}),
          }
        )
      case UserTransactionType.ORDER:
        return intl.formatMessage(
          {id: 'BILLING.MESSAGE.ORDER'},
          {
            order: data?.target ?? '',
          }
        )
      case UserTransactionType.PAID:
        return intl.formatMessage(
          {id: 'BILLING.MESSAGE.PAID'},
          {
            name: intl.formatMessage({id: data?.target ?? ''}),
          }
        )
    }
  }
  const renderBillingItem = (item: BillingModel) => {
    let data: any = item.data
    if (typeof data === 'string') {
      data = JSON.parse(data)
    }

    return (
      <tr key={item.id} className={item.type === UserTransactionType.CREDIT ? 'table-warning' : ''}>
        <td> {item.id.toString().padStart(10, '0')}</td>
        <td> {generateDescriptionWithTypeAndData(item)} </td>
        <td>{intl.formatMessage({id: 'BILLING.TYPE.' + item.type})}</td>
        <td>
          <span
            className={
              'badge py-3 px-4 fs-7 badge-light-' +
              (item.action === UserTransactionAction.IN ? 'primary' : 'warning')
            }
          >
            {intl.formatMessage({id: 'BILLING.ACTION.' + item.action})}
          </span>
        </td>
        <td className='fw-bolder'>
          <span
            className={
              'badge fs-base badge-light-' +
              (item.action === UserTransactionAction.IN ? 'success' : 'danger')
            }
          >
            {item.action === UserTransactionAction.IN ? '+' : '-'}
            {new Intl.NumberFormat(intl.locale, {style: 'decimal'}).format(item.creditAmount)}
          </span>
        </td>
        <td>
          <span className='text-gray-600 fw-bold fs-6'>
            {item.fiatAmount
              ? new Intl.NumberFormat(intl.locale, {style: 'decimal'}).format(item.fiatAmount) +
                intl.formatMessage({id: data.currency ?? ' '})
              : '-'}
          </span>
        </td>

        <td> {moment(item.createdAt).format(process.env.REACT_APP_DATE_FORMAT)} </td>
        <td>{renderItemActions(item)}</td>
      </tr>
    )
  }
  return (
    <>
      <div className='table-responsive'>
        {/* begin::Table */}
        <table className='table table-hover table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
          {/* begin::Table head */}
          <thead>
            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
              <th>Id</th>
              <th className='min-w-300px'>
                {intl.formatMessage({id: 'BILLING.TABLE.DESCRIPTION'})}
              </th>
              <th className='min-w-120px'>{intl.formatMessage({id: 'BILLING.TABLE.CATEGORY'})}</th>
              <th className='min-w-140px'>{intl.formatMessage({id: 'BILLING.TABLE.TYPE'})}</th>
              <th className='min-w-120px'>{intl.formatMessage({id: 'BILLING.TABLE.CREDIT'})}</th>
              <th className='min-w-120px'>{intl.formatMessage({id: 'BILLING.TABLE.FIAT'})}</th>
              <th className='min-w-120px'>{intl.formatMessage({id: 'BILLING.TABLE.TIME'})}</th>
              <th className='min-w-100px text-end'>
                {intl.formatMessage({id: 'BILLING.TABLE.ACTION'})}
              </th>
            </tr>
          </thead>
          {/* end::Table head */}
          {/* begin::Table body */}
          <tbody>{data?.map((item: any) => renderBillingItem(item))}</tbody>
          {/* end::Table body */}
        </table>
        {/* end::Table */}
      </div>
      <div className='d-flex justify-content-end pt-10'>
        <Paginator
          currentPage={filter.page}
          totalPages={filter.pageCount || 1}
          onPageChange={handlePageChanged}
        />
      </div>
    </>
  )
}

export default BillingTable
