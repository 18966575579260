import {Suspense, lazy, useEffect, useRef} from 'react'
import {Redirect, Route, Switch, useHistory} from 'react-router-dom'
import {FallbackView} from '../../_metronic/partials'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import OrderPage from '../pages/orders/OrderPage'
import {CreateOrderPage} from '../pages/orders/CreateOrderPage'
import {OrderDetail} from '../pages/orders/OrderDetail'
import {ItemDetail} from '../pages/items/ItemDetail'
import {
  getNotificationsSizeOnce,
  onNotifications,
} from '../modules/apps/notifications/firebase/notifications'
import {toast} from 'react-toastify'
import SettingReference from '../pages/setting-reference/SettingReference'
import Billing from '../pages/billings/Billing'
import SettingReferenceDetail from '../pages/setting-reference/components/SettingReferenceDetail'
import PricingPage from '../pages/pricing/pricing'
import {EditorTool} from '../pages/editorTool/EditorTool'
import {NOTIFICATION_TYPES} from '../shared/constants'
import {useDispatch} from 'react-redux'
import {setShowWelcomeModal} from '../modules/auth'
import { ServiceDetailWrapper } from '../pages/services/ServiceDetail'
import { useIntl } from 'react-intl'
import { BankTransferRequest } from '../pages/bankTransferRequest/bankTransferRequest'

const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))

export function PrivateRoutes() {
  const allowNotificationRef = useRef(0)
 
  const intl = useIntl()
  const history = useHistory()
  const dispatch = useDispatch()
  useEffect(() => {
    getNotificationsSizeOnce((size) => {
      onNotifications((value) => {
        allowNotificationRef.current += 1
        if (allowNotificationRef.current > size) {
          if (value.type === NOTIFICATION_TYPES.WELCOME) {
            dispatch(setShowWelcomeModal(true))
            return
          }
          toast.info(
            <div>
              <div>
                <strong>{intl.formatMessage({id:value.header})}</strong>
              </div>
              <div>{intl.formatMessage({id:value.message})}</div>
            </div>,
            {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 3000,
              hideProgressBar: true,
              closeOnClick: false,
              onClick: () => {
                if (value.target) {
                  const pathname = new URL(value.target).pathname
                  history.push(pathname)
                }
              },
            }
          )
        }
      })
    })
  }, [])

  return (
    <Suspense fallback={<FallbackView />}>
      <Switch>
        <Route exact path='/services' component={DashboardWrapper} />
        <Route exact path='/orders/create' component={CreateOrderPage} />
        <Route exact path='/orders/:id' component={OrderDetail} />
        <Route exact path='/edit/:id' component={EditorTool} />
        <Route exact path='/orders' component={OrderPage} />
        <Route exact path='/orders/:orderId/items/:id' component={ItemDetail} />
        <Route path='/pricing' component={PricingPage} />
        <Route path='/crafted/pages/profile' component={ProfilePage} />
        <Route exact path='/services/:id' component={ServiceDetailWrapper} />
      
        {/* <Route exact path='/builder' component={BuilderPageWrapper} />
     
        <Route exact path='/crafted/pages/wizards' component={WizardsPage} />
        <Route exact path='/crafted/widgets' component={WidgetsPage} />
        <Route exact path='/crafted/account' component={AccountPage} />
        <Route exact path='/apps/chat' component={ChatPage} />
        <Route exact path='/menu-test' component={MenuTestPage} />
        <Route exact path='/services/:id' component={ServiceDetailWrapper} />
       
        <Route exact path='/orders/create' component={CreateOrder} />
        <Route exact path='/orders/pending/:id' component={ContinueOrder} />
  
        <Route exact path='/inquiries' component={Inquiries} />
        <Route exact path='/inquiries/:itemId/create' component={InquiryDetail} />
        <Route exact path='/inquiries/:itemId/:inquiryId' component={InquiryDetail} />
        */}
        <Route exact path='/setting-references' component={SettingReference} />
        <Route exact path='/setting-references/:settingId' component={SettingReferenceDetail} />
        <Route exact path='/setting-references/create' component={SettingReferenceDetail} />
        <Route exact path='/billings' component={Billing} />
        <Redirect exact from='/auth' to='/' />
        <Redirect exact from='/' to='/services' />
        <Redirect exact to='/error/404' />
      </Switch>
    </Suspense>
  )
}
