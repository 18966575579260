
export const getUserFullName = (user: {
    firstName: string,
    lastName: string
}, language: string): string => {
    if (language === 'ja') {
        return `${user.lastName} ${user.firstName}`;
    }
    return `${user.firstName} ${user.lastName}`;

}

export const displayUseExchangeRate = (rate: number | undefined) => {
    if (rate === undefined) return 0;
    return rate.toFixed(rate % 1 === 0 ? 0 : 2);
}

export function formatNumber(num: number): string {
    return num % 1 === 0 ? num.toString() : num.toFixed(2);
  }