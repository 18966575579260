import {PolotnoContainer, SidePanelWrap, WorkspaceWrap} from 'polotno'
import {Toolbar} from 'polotno/toolbar/toolbar'
import {ZoomButtons} from 'polotno/toolbar/zoom-buttons'
import {SidePanel} from 'polotno/side-panel'
import {Workspace} from 'polotno/canvas/workspace'
import {createStore} from 'polotno/model/store'
import {FC, useEffect, useRef, useState} from 'react'
import {RouteComponentProps, useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {
  getDetailOutput,
  selectCurrentItemOutput,
  selectItemLoading,
  updateItemOutput,
} from '../items/redux/itemRedux'
import {useAppSelector} from '../../../setup/redux/hooks'
import {ItemOutputModel} from '../items/models/ItemOutputModel'

import '@blueprintjs/core/lib/css/blueprint.css'
import {Button, Modal} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {KTSVG} from '../../../_metronic/helpers'
import {setTranslations} from 'polotno/config'
import enTranlations from '../../../_metronic/i18n/messages/polotno_en.json'
import jaTranlations from '../../../_metronic/i18n/messages/polotno_ja.json'
import {toast} from 'react-toastify'
import ConfirmModal from '../../components/ConfirmModal'
// log full translations object

const store = createStore({
  key: process.env.REACT_APP_POLOTNO_API_KEY as string,
  showCredit: false,
})

const SPACE_GAP = 50
interface RouteParams {
  id: string
}
const SOURCE = {
  RAW: 'Origin',
  OUTPUT: 'Output',
}
interface Props extends RouteComponentProps<RouteParams> {}
export const EditorTool: FC<Props> = (props) => {
  const didRequest = useRef(false)
  const rawOutputImage: ItemOutputModel | undefined = useAppSelector(selectCurrentItemOutput)
  const [source, setSource] = useState(SOURCE.OUTPUT)
  const itemId = props.match.params.id
  const [showConfirm, setShowConfirm] = useState(false)
  const [isImageChanged, setIsImageChanged] = useState(false)
  const loading = useAppSelector(selectItemLoading)
  const dispatch = useDispatch()
  const history = useHistory()
  const intl = useIntl()
  const locals = intl.locale === 'en' ? enTranlations : jaTranlations
  setTranslations(locals)
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getDetailOutput(itemId))
    }
    if (rawOutputImage) {
      store.addPage({
        name: rawOutputImage.name,
        width: rawOutputImage.width + SPACE_GAP * 2,
        height: rawOutputImage.height + SPACE_GAP * 2,
      })
      store.activePage.addElement({
        type: 'image',
        x: SPACE_GAP, // x-coordinate
        y: SPACE_GAP, // y-coordinate
        width: rawOutputImage.width, // width
        height: rawOutputImage.height, // height
        removable: false,
        src:
          'data:' +
          rawOutputImage.imageType +
          ';base64,' +
          (source === SOURCE.RAW ? rawOutputImage.rawImage : rawOutputImage.rawOutputImage), // Base64 string of the image
      })
      setIsImageChanged(false)
    }
    const unsubscribe = store.on('change', () => {
      setIsImageChanged(true)
    })
    return () => {
      didRequest.current = true
      //remove all pages

      const pageIds = store.pages.map((page) => page.id)
      if (pageIds) {
        store.deletePages(pageIds)
      }
      unsubscribe()
    }
  }, [rawOutputImage, source])
  /**
   *onSaveButtonClick
   * @returns
   */
  const onSaveButtonClick = async () => {
    if (!rawOutputImage || !store) return
    const base64data = await store.toDataURL({
      mimeType: (rawOutputImage.imageType as 'image/jpeg' | 'image/png') ?? 'image/jpeg',
    })
    const currentPage = store.activePage
    if (currentPage) {
      const hasImageElement = currentPage.children.some((element) => element.type === 'image')
      if (!hasImageElement) {
        toast.error(intl.formatMessage({id: 'EDITOR.NO_IMAGE_ELEMENT'}))
        return
      }

      const width = currentPage.width
      const height = currentPage.height
      let newOuput: ItemOutputModel = {
        id: rawOutputImage?.id,
        rawImage: base64data.split(',')[1],
        imageType: rawOutputImage?.imageType,
        width: width !== 'auto' ? width : rawOutputImage.width,
        height: height !== 'auto' ? height : rawOutputImage.height,
        name: rawOutputImage?.name,
        orderId: rawOutputImage?.orderId,
      }
      await dispatch(updateItemOutput(newOuput))
      setTimeout(() => {
        history.push('/orders/' + rawOutputImage.orderId)
      }, 3000)
    }
  }
  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-header'>
        <div className='card-title m-0'>
          <h3 className='fw-bolder m-0'>{intl.formatMessage({id: 'EDITOR.HEADER'})}</h3>
        </div>
        <div className='mx-auto align-self-center' data-kt-buttons='true'>
          {Object.values(SOURCE).map((item) => (
            <button
              key={item}
              className={
                'btn btn-color-gray-400 btn-active btn-active-primary px-6 py-3 me-2 ' +
                (item === source ? 'active' : 'false')
              }
              onClick={() => (isImageChanged ? setShowConfirm(true) : setSource(item))}
            >
              {intl.formatMessage({id: 'ITEM.VIEW.' + item})}
            </button>
          ))}
        </div>
        <div className='align-self-center'>
          <Button
            onClick={onSaveButtonClick}
            disabled={!rawOutputImage || loading}
            className='btn btn-primary'
          >
            <KTSVG
              path={'/media/icons/duotune/files/fil008.svg'}
              className='svg-icon svg-icon-2x svg-icon-success'
            ></KTSVG>
            {intl.formatMessage({id: 'EDITOR.BTN.SAVE'})}
          </Button>
        </div>
      </div>
      <div className='collapse show p-5'>
        <PolotnoContainer style={{width: '100%', height: '100vh'}}>
          <SidePanelWrap>
            <SidePanel store={store} sections={[]} defaultSection='custom' />
          </SidePanelWrap>

          <WorkspaceWrap>
            <Toolbar store={store} downloadButtonEnabled={true} />
            <Workspace store={store} pageControlsEnabled={false} />
            <ZoomButtons store={store} />
          </WorkspaceWrap>
        </PolotnoContainer>
      </div>
      <ConfirmModal
        onClose={() => setShowConfirm(false)}
        isShow={showConfirm}
        onConfirm={() => {
          setSource(source === SOURCE.RAW ? SOURCE.OUTPUT : SOURCE.RAW)
          setShowConfirm(false)
        }}
        message={intl.formatMessage({id: 'EDITOR.CONFIRM.MESSAGE'})}
      />
    </div>
  )
}
