import {FC, useEffect, useRef, useState} from 'react'
import {Button} from 'react-bootstrap-v5'
import {useIntl} from 'react-intl'
import {useDispatch} from 'react-redux'
import {
  buySubscription,
  customPlanRequest,
  getAllSubscription,
  selectPricingLoading,
  selectSelecteesSubscription,
  selectSubscriptionPlan,
  setSelectedSubscription,
} from './redux/pricingRedux'
import {useAppSelector} from '../../../setup/redux/hooks'
import {SubscriptionPlanModel} from './models/subscriptionPlanModel'

import {
  CurrencyCode,
  CUSTOM_PLAN,
  NONE_CHECKOUT_PLAN,
  PAY_AS_U_GO_PLAN,
  PlanType,
  PriceType,
} from './pricing.constant'
import {useHistory} from 'react-router-dom'
import {selectUser} from '../../modules/auth'
import {COUNTRY_CODES} from '../../modules/profile/constants'
import {PAYMENT_METHOD} from '../../shared/constants'
import PaymentModal from '../../components/paymentModal'
import {displayAmountBaseOnCurrency, mappingCurrencyCodeToCurrencySymbol} from './utils'
import { formatNumber } from '../../shared/utils'

const PricingPage: FC = () => {
  const dispatch = useDispatch()
  const intl = useIntl()

  const history = useHistory()
  const didRequest = useRef(true)
  const user = useAppSelector(selectUser)
  const [planType, setPlanType] = useState(PlanType.PAY_AS_U_GO)
  const [isShowPaymentDetail, setIsShowPaymentDetail] = useState(false)
  const [currency, setCurrency] = useState(
    user?.countryCode === COUNTRY_CODES.JP ? CurrencyCode.JPY : CurrencyCode.USD
  )
  const plans: SubscriptionPlanModel[] = useAppSelector(selectSubscriptionPlan)

  const selectedPlan: SubscriptionPlanModel | undefined = useAppSelector(
    selectSelecteesSubscription
  )
  const loading: boolean = useAppSelector(selectPricingLoading)
  const handleClose = () => {
    history.push('/')
  }

  const getAllSubscriptionPlan = () => {
    if (didRequest.current) {
      dispatch(getAllSubscription())

      didRequest.current = true
    }
  }
  const handleUpgradePlanClick = () => {
    if (!selectedPlan) {
      return
    }
    if (selectedPlan.id === PAY_AS_U_GO_PLAN) {
      dispatch(buySubscription({currency, paymentMethod: PAYMENT_METHOD.VISA}))
    } else if (selectedPlan.id === CUSTOM_PLAN) {
      dispatch(customPlanRequest({message: intl.formatMessage({id: 'MODAL.PLAN.request_message'})}))
    } else {
      setIsShowPaymentDetail(true)
    }
  }
  useEffect(() => {
    getAllSubscriptionPlan()

    if (Number(user?.planId) > 14) {
      setPlanType(PlanType.ENTERPRISE)
    } else if (Number(user?.planId) > 9) {
      setPlanType(PlanType.SUBSCRIPTION)
    }
    if (user && user.countryCode === COUNTRY_CODES.JP) {
      setCurrency(CurrencyCode.JPY)
    }
  }, [user])

  return (
    <div className='card mb-10'>
      <div className='card-body p-10'>
        <div className='text-center mb-10'>
          <div className='pt-0 pb-10 px-5 px-xl-20'>
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>{intl.formatMessage({id: 'MODAL.PLAN.title'})}</h1>

              <div className='text-muted fw-bold fs-5'>
                {intl.formatMessage({id: 'MODAL.PLAN.sub_title'})}
                <a
                  href={process.env.REACT_APP_WEBSITE + '/settlement'}
                  target='_blank'
                  rel='noreferrer'
                  className='link-primary fw-bolder'
                >
                  {intl.formatMessage({id: 'MODAL.PLAN.price_guide'})}
                </a>
              </div>
              {currency === CurrencyCode.JPY && (
                <div className='text-danger fw-bold fs-7'>
                  {intl.formatMessage({id: 'MODAL.PLAN.tax_guide'})}
                </div>
              )}
              {/* <div
                className='nav-group nav-group-outline position-absolute '
                style={{marginTop: '30px', marginRight: '30px', top: 0, right: 0}}
                data-kt-buttons='true'
              >
                {Object.values(CurrencyCode).map((item) => (
                  <a
                    key={item}
                    href='#'
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-primary px-6 py-3 me-2 ' +
                      (item === currency ? 'active' : 'false')
                    }
                    onClick={() => setCurrency(item)}
                  >
                    {mappingCurrencyCodeToCurrencySymbol(item)}
                  </a>
                ))}
              </div> */}
            </div>

            <div className='d-flex flex-column'>
              <div className='nav-group nav-group-outline mx-auto' data-kt-buttons='true'>
                {Object.values(PlanType).map((item) => (
                  <a
                    key={item}
                    href='#'
                    className={
                      'btn btn-color-gray-400 btn-active btn-active-secondary px-6 py-3 me-2 ' +
                      (item === planType ? 'active' : 'false')
                    }
                    onClick={() => setPlanType(item)}
                  >
                    {intl.formatMessage({id: 'MODAL.PLAN.' + item})}
                  </a>
                ))}
              </div>
              <div className='row mt-5 g-5'>
                {plans
                  .filter((item) => item.type === planType)
                  .map((plan, index) => {
                    const priceData = plan.priceData?.find((price) => price.currency === currency)
                    const price = plan.id === CUSTOM_PLAN ? undefined : priceData
                    return (
                      <div className='col col-xl-4 p-3'>
                        <div
                          onClick={() => {
                            dispatch(setSelectedSubscription(plan))
                          }}
                          className={
                            `h-100 nav-link btn btn-outline btn-outline-dashed btn-color-dark d-flex flex-stack text-start p-6 ` +
                            (plan.id === selectedPlan?.id &&
                              ' active btn-active btn-active-primary ')
                          }
                          key={index}
                        >
                          <div className='align-items-center me-2 w-100'>
                            {/* <div className='form-check form-check-custom form-check-solid form-check-success me-6'>
                            <input
                              className='form-check-input'
                              type='radio'
                              name='plan'
                              value={plan.id}
                              checked={selectedPlan?.id === plan.id}
                              onChange={(e) => {
                                dispatch(setSelectedSubscription(plan))
                              }}
                            />
                          </div> */}

                            <div className='text-center texta'>
                              <h3 className=' align-items-center fs-2.5 fw-bolder flex-wrap'>
                                {plan.id === PAY_AS_U_GO_PLAN ? intl.formatMessage({id: 'MODAL.PLAN.PAY_AS_U_GO_PLAN'}) :plan.name}
                              </h3>
                              <div className='fw-bold mt-2 mb-2 fs-7'>
                                {plan.shortDesc}
                              </div>
                              <span className='badge badge-light-success ms-2 fs-7'>
                                {![CUSTOM_PLAN, PAY_AS_U_GO_PLAN].includes(plan.id) && price
                                  ? '+' +
                                    new Intl.NumberFormat(intl.locale, {style: 'decimal'}).format(
                                      plan.point
                                    ) +
                                    intl.formatMessage({id: 'SYSTEM.CURRENCY'})
                                  : intl.formatMessage({id: 'MODAL.PLAN.CURRENCY.' + plan.id})}
                              </span>
                              <div className='fw-bold mt-3 text-danger'>
                                { price
                                  ? intl.formatMessage(
                                      {id: 'MODAL.PLAN.rate'},
                                      {
                                        rate:
                                          currency === CurrencyCode.JPY
                                            ? Math.round((price.price / plan.point) * 1.2 * 100) /
                                              100
                                            : formatNumber(((price.price / plan.point) * 1.2) / 100),
                                        currency: intl.formatMessage({
                                          id: mappingCurrencyCodeToCurrencySymbol(currency),
                                        }),
                                      }
                                    )
                                  : intl.formatMessage({id: 'MODAL.PLAN.CUSTOM'})}
                              </div>
                              <div className='ms-5'>
                                <span className='fs-2x fw-bolder'>
                                  {![CUSTOM_PLAN, PAY_AS_U_GO_PLAN].includes(plan.id) && price
                                    ? displayAmountBaseOnCurrency(price.price, currency)
                                    : intl.formatMessage({id: 'MODAL.PLAN.' + plan.id})}
                                </span>
                                <span className='fs-7 opacity-50'>
                                  <span data-kt-element='period'>
                                    {plan.priceType === PriceType.SUBSCRIPTION &&
                                      intl.formatMessage({id: 'MODAL.PLAN.monthly'})}
                                  </span>
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
              </div>

              {/* <div className='row mt-10'>
                <div className='col-lg-12'>
                  <div className='tab-content rounded h-100 bg-light p-10'>
                    <div className='tab-pane fade fadeIn show active'>
                      <div className='pb-5 text-center'>
                        <h2 className='fw-bolder text-dark'>
                          {intl.formatMessage({id: 'MODAL.PLAN.detail_plan_title'})}
                        </h2>

                        <div className='text-gray-400 fw-bold'>{selectedPlan?.desc}</div>
                      </div>

                      <div className='pt-1'>
                        {selectedPlan?.features?.map((feature, i) => {
                          return (
                            <div
                              className={
                                `d-flex align-items-center` +
                                (i !== selectedPlan.features!.length - 1 && ' mb-7')
                              }
                              key={`${i}-${feature.desc}`}
                            >
                              <span className='fw-bold fs-5 flex-grow-1 text-gray-700'>
                                {feature.desc}
                              </span>
                              {Boolean(feature.available) ? (
                                <KTSVG
                                  path='/media/icons/duotune/general/gen043.svg'
                                  className='svg-icon-1 svg-icon-success'
                                />
                              ) : (
                                <KTSVG
                                  path='/media/icons/duotune/general/gen040.svg'
                                  className='svg-icon-1'
                                />
                              )}
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>

            <div className='d-flex flex-center flex-row-fluid pt-12'>
              <Button variant='secondary' onClick={handleClose} className='btn btn-light me-3'>
                {intl.formatMessage({id: 'MODAL.PLAN.cancel_btn'})}
              </Button>
              <Button
                onClick={handleUpgradePlanClick}
                disabled={loading || selectedPlan?.id === user?.planId}
                type='submit'
                variant='primary'
              >
                {loading ? (
                  <span className='indicator-progress' style={{display: 'block'}}>
                    {intl.formatMessage({id: 'Login.form.WAITING'})}
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                ) : planType === PlanType.PAY_AS_U_GO ? (
                  intl.formatMessage({id: 'MODAL.PLAN.buy_btn'})
                ) : selectedPlan?.id === CUSTOM_PLAN ? (
                  intl.formatMessage({id: 'MODAL.PLAN.call_btn'})
                ) : (
                  intl.formatMessage({id: 'MODAL.PLAN.upgrade_btn'})
                )}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <PaymentModal
        isShow={isShowPaymentDetail}
        onClose={() => setIsShowPaymentDetail(false)}
        onOk={(paymentMethod: PAYMENT_METHOD) => {
          setIsShowPaymentDetail(false)
          dispatch(buySubscription({currency, paymentMethod}))
        }}
        paymentDetail={[
          {
            itemName: selectedPlan?.name ?? '',
            unitPrice:
              selectedPlan?.priceData?.find((price) => price.currency === currency)?.price ?? 0,
            quantity: 1,
            currency: currency,
            paymentType: selectedPlan?.priceType,
          },
        ]}
      />
    </div>
  )
}

export default PricingPage
