import axios from 'axios'
import { UserModel } from '../models/UserModel'
import { LoginRequestModel, SocialLoginRequestModel } from '../models/LoginRequestModel'
import { UserRegisterModel, SocialUserRegisterModel } from '../models/UserRegisterModel'

const API_URL = process.env.REACT_APP_API_URL
const BASE_AUTH_URL = `${API_URL}/auth/`

export const LOGIN_URL = `${API_URL}/auth/login`
export const SOCIAL_LOGIN_URL = `${API_URL}/auth/social-login`
export const REGISTER_URL = `${API_URL}/auth/signup`
export const SOCIAL_REGISTER_URL = `${API_URL}/auth/social-signup`
export const REQUEST_PASSWORD_URL = `${API_URL}/account/forgot-password/init`
export const RESET_PASSWORD_URL = `${API_URL}/account/forgot-password/finish`

/**
 * Call Api for login
 * @param loginRequest
 * @returns
 */
export function loginRequest(loginRequest: LoginRequestModel) {
  return axios.post(BASE_AUTH_URL + 'login', loginRequest)
}
/**
 * verifyToken
 * @param token
 * @returns
 */
export function verifyToken(token: string) {
  return axios.post<UserModel>(BASE_AUTH_URL + 'verify-token', {
    accessToken: token,
  })
}
/**
 * verifyEmailAddress
 * @param token
 * @returns
 */
export function verifyEmailAddress(token: string, email: string, isBankTransfer?: boolean) {
  return axios.post(BASE_AUTH_URL + 'verify-email', {
    code: token,
    email,
    isBankTransfer
  })
}
/**
 * verifyEmailAddress
 * @param token
 * @returns
 */
export function sendVerifyEmailAddress(email: string) {
  return axios.post<boolean>(BASE_AUTH_URL + 'resent-verify-email', {
    email
  })
}
/**
 *
 * @param user
 * @returns
 */
export function registerNewUser(user: UserRegisterModel) {
  return axios.post(REGISTER_URL, user)
}

/**
 * Social login
 * @param loginRequest
 * @returns
 */
export function socialLoginRequest(loginRequest: SocialLoginRequestModel) {
  return axios.post(SOCIAL_LOGIN_URL, loginRequest)
}

/**
 * Register new user through social platform
 * @param socialUserRegister
 * @returns
 */
export function socialRegisterRequest(socialUserRegister: SocialUserRegisterModel) {
  return axios.post(SOCIAL_REGISTER_URL, socialUserRegister)
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{ result: boolean, message: string }>(REQUEST_PASSWORD_URL, {
    email,
  })
}



export function resetPassword(token: string, newPassword: string) {
  return axios.post(RESET_PASSWORD_URL, {
    token,
    newPassword,
  })
}
