/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect} from 'react'
import {Redirect, Route, Switch} from 'react-router-dom'
import {Registration} from './components/Registration'
import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {ResetPassword} from './components/ResetPassword'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {useIntl} from 'react-intl'
import {Languages} from './LanguageSwitch'
import './AuthPage.scss'
import VerifyUserPage from '../../pages/verifyUser/verifyUser'
export function AuthPage() {
  const intl = useIntl()
  useEffect(() => {
    document.body.classList.add('bg-white')
    return () => {
      document.body.classList.remove('bg-white')
    }
  }, [])
  return (
    <div className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'>
      {/* begin::Content */}
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        {/* begin::Logo */}
        <a href='#' className='mb-12'>
          <img alt='Logo' src={toAbsoluteUrl('/media/logos/logo-1.svg')} className='h-100px' />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className='bg-white rounded shadow-sm p-10 p-lg-15 mx-auto'>
          <Switch>
            <Route path='/auth/login' component={Login} />
            <Route path='/auth/registration' component={Registration} />
            <Route path='/auth/forgot-password' component={ForgotPassword} />
            <Route path='/auth/reset-password' component={ResetPassword} />
            <Route exact path='/auth/verify' component={VerifyUserPage} />
            <Redirect from='/auth' exact={true} to='/auth/login' />
            <Redirect to='/auth/login' />
          </Switch>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className='w-lg-650px w-100 mx-auto text-gray-700 d-flex flex-center flex-column-auto pb-10'>
        <div className='w-100 d-flex flex-column flex-md-row align-items-center justify-content-between'>
          <div className='text-dark order-2 order-md-1 text-center'>
            <span className='fw-bold me-1 fs-7'>
              {intl.formatMessage(
                {id: 'AuthPage.VERSION'},
                {version: process.env.REACT_APP_VERSION}
              )}
            </span>
          </div>
          <div className='text-dark order-2 order-md-1 fs-9 float-left'>
            <span className='fw-bold me-1'>Copyright {new Date().getFullYear()} &copy;</span>
            <a
              target='_blank'
              href={process.env.REACT_APP_WEBSITE}
              className='text-gray-800 text-hover-primary'
              rel='noreferrer'
            >
              A-LEADS HOLDINGS PTE.LTD.
            </a>
            All Rights Reserved.
          </div>

          <Languages />
        </div>
      </div>
      {/* end::Footer */}
    </div>
  )
}
