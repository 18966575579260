import React, {Suspense} from 'react'
import {BrowserRouter} from 'react-router-dom'
import {I18nProvider} from '../_metronic/i18n/i18nProvider'
import {LayoutProvider, LayoutSplashScreen} from '../_metronic/layout/core'
import AuthInit from './modules/auth/components/AuthInit'
import {Routes} from './routing/Routes'
import {ToastContainer} from 'react-toastify'
import {ConnectedRouter} from 'connected-react-router'
import {history} from '../setup/history'
import 'react-toastify/dist/ReactToastify.css'
type Props = {
  basename: string
}

const App: React.FC<Props> = ({basename}) => {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <ConnectedRouter history={history}>
        <I18nProvider>
          <LayoutProvider>
            <AuthInit>
              <Routes />
            </AuthInit>
          </LayoutProvider>
          <ToastContainer
            position='bottom-right'
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={'colored'}
          />
        </I18nProvider>
      </ConnectedRouter>
    </Suspense>
  )
}

export {App}
