import {useEffect, useRef, FC} from 'react'
import {useAppSelector} from '../../../../setup/redux/hooks'
import {
  filterDataChange,
  getAll,
  selectOrderDisplayMode,
  selectOrderFilter,
  selectOrderList,
  updateOrderList,
} from '../redux/OrderRedux'
import {useDispatch} from 'react-redux'
import {OrderDisplayMode} from '../constants'
import OrderGridView from './OrderGridView'
import OrderListView from './OrderListView'
import {listenOrderChange} from '../firebase/order'
import {OrderModel} from '../models/OrderModel'
import {off} from 'firebase/database'
import InfiniteScroll from 'react-infinite-scroll-component'
import {useIntl} from 'react-intl'
type Props = {
  className?: string
}
const OrderTable: FC<Props> = (props) => {
  const didRequest = useRef(false)
  const orders = useAppSelector(selectOrderList)
  const displayMode = useAppSelector(selectOrderDisplayMode)
  const intl = useIntl()
  const orderFilerData = useAppSelector(selectOrderFilter)
  const dispatch = useDispatch()
  useEffect(() => {
    if (!didRequest.current) {
      dispatch(getAll())
    }
    const orderListener = listenOrderChange((orders: OrderModel[]) => {
      if (orders) {
        handleOrderChangeFromFirebase(orders)
      }
    })
    return () => {
      didRequest.current = true
      if (orderListener) {
        off(orderListener)
      }
    }
  }, [])
  const fetchData = () => {
    dispatch(filterDataChange({...orderFilerData, page: Number(orderFilerData.page) + 1}))
  }
  const refresh = () => {
    dispatch(getAll())
  }
  /**
   * handleOrderChangeFromFirebase
   * update current list with data realtime from Firebase
   * @param updatedOrder
   */
  const handleOrderChangeFromFirebase = (updatedOrder: OrderModel[]) => {
    dispatch(updateOrderList(Object.values(updatedOrder)))
  }
  return (
    <InfiniteScroll
      dataLength={orders.length} //This is important field to render the next data
      next={fetchData}
      style={{
        overflowX: 'hidden',
      }}
      hasMore={orders.length !== orderFilerData.itemCount}
      loader={
        <h4 className='mt-5' style={{textAlign: 'center'}}>
          {/* {intl.formatMessage({id: 'SYSTEM.LOADING'})} */}
        </h4>
      }
      endMessage={
        <p className='mt-5' style={{textAlign: 'center'}}>
          <b>{intl.formatMessage({id: 'SYSTEM.TABLE.SEEN_ALL'})}</b>
        </p>
      }
      refreshFunction={refresh}
    >
      {displayMode === OrderDisplayMode.Grid ? (
        <OrderGridView orders={orders} />
      ) : (
        <OrderListView orders={orders} />
      )}
    </InfiniteScroll>
  )
}

export default OrderTable
