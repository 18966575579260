import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { UserModel } from '../models/UserModel'
import { RootState } from '../../../../setup/redux/Store'
import { UserRegisterModel, SocialUserRegisterModel } from '../models/UserRegisterModel'
import { LoginRequestModel, SocialLoginRequestModel } from '../models/LoginRequestModel'
import { toast } from 'react-toastify'
import { intl } from '../../../../_metronic/i18n/i18nProvider'
import { verify } from 'crypto'
export interface IAuthState {
  user?: UserModel
  accessToken?: string
  loading: boolean,
  errors: string[],
  isShowWelcomePopup: boolean,
  tempUser?: {
    email: string,
    isBankTransfer?: boolean
  }
}

const initialState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  loading: false,
  errors: [],
  isShowWelcomePopup: false,
  tempUser: undefined
}

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action: PayloadAction<{ loginRequest: LoginRequestModel }>) => {
      state.loading = true
    },
    socialLogin: (state, action: PayloadAction<{ loginRequest: SocialLoginRequestModel }>) => {
      state.loading = true
    },
    register: (state, action: PayloadAction<{ user: UserRegisterModel }>) => {
      state.loading = true
    },
    socialRegister: (state, action: PayloadAction<{ registerRequest: SocialUserRegisterModel }>) => {
      state.loading = true
    },
    logout: (state) => {
      state.accessToken = undefined
      state.user = undefined
    },
    requestUser: (state) => {
      state.loading = true
    },
    verifyEmail: (state, action: PayloadAction<string>) => {
      state.loading = true
    },
    sendVerifyEmail: (state) => {
      // state.loading = true
    },
    setUser: (state, action: PayloadAction<{ user: UserModel }>) => {
      state.user = action.payload.user
      state.loading = false
      state.errors = []
    },
    setTempUser: (state, action: PayloadAction<{ email: string, isBankTransfer: boolean | undefined }>) => {
      state.loading = false
      state.tempUser = action.payload
    },
    setToken: (state, action: PayloadAction<{ token: string }>) => {
      state.loading = false
      state.accessToken = action.payload.token
    },
    setShowWelcomeModal: (state, action: PayloadAction<boolean>) => {
      state.isShowWelcomePopup = action.payload
    },
    requestFail: (state, action: PayloadAction<{ errors: string[] }>) => {
      state.loading = false
      state.errors = action.payload.errors
      toast.error(intl.formatMessage({ id: "SERVER." + action.payload.errors[0] }))
    }

  }
})

export const {
  login,
  socialLogin,
  register,
  socialRegister,
  setToken,
  logout,
  requestUser,
  setUser,
  setTempUser,
  setShowWelcomeModal,
  requestFail,
  verifyEmail,
  sendVerifyEmail
} = authSlice.actions
export const selectUser = (state: RootState) => state.auth.user;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectAuthErrors = (state: RootState) => state.auth.errors;
export const selectAccessToken = (state: RootState) => state.auth.accessToken;
export const selectIsShowWelcomeModal = (state: RootState) => state.auth.isShowWelcomePopup;

export default authSlice.reducer;
